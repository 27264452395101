
.subpage-style h2{ 
    @apply  text-[32px] mb-2;
}
.subpage-style h4{ 
    @apply text-xl lg:text-2xl mb-2 font-bold;
}
.subpage-style h4 strong{
    @apply font-bold
}
.subpage-style p{
    @apply text-[16px] text-black text-justify mb-4;
}
.subpage-style ul{
    @apply ml-10 mb-5
}
.subpage-style ul li {
    @apply text-[16px] text-black text-justify  list-disc
}