@import url(inter.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
        * {
            font-family: Inter, sans-serif !important;
            @apply m-0 p-0 box-border leading-[1.75];
        }
        ::before,
        ::after {
            @apply m-0 p-0 font-sans box-border leading-[1.75];
        }

        html{
            font-family: Inter, sans-serif !important;
            @apply scroll-smooth;
        }
        a {
            @apply no-underline;
        }
        ol, ul {
            @apply pl-0 m-0;
        }
        img {
            @apply max-w-full;
        }
        body {
            font-family: Inter, sans-serif !important;
            @apply p-0 m-0 box-border text-[#354A55];
        }
   }
 
 @layer components{
    
    .bi {
        @apply inline-block not-italic  no-underline w-[1em] mx-[0.2em] text-center font-normal normal-case leading-[1em] antialiased;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .btn_call{
        @apply inline-block text-[1.25rem] leading-[1.375] text-white font-bold py-[1rem] px-[2rem] bg-[#d01b34] mx-auto mt-[1.5rem] rounded-full no-underline lg:text-[1.5rem] lg:leading-[1.375] lg:mt-[2.25rem];
      }

      .feature_li{
        @apply flex mb-[1.25rem] relative text-[0.875rem] leading-[1.25rem] lg:mb-0;
      }

      .feature{
        @apply text-white md:flex md:items-center md:text-[1rem] md:leading-[1.5];
      }

      .bg{
        @apply absolute inset-0 bg-[#354A55] bg-cover bg-no-repeat bg-[url('../../img//SQ_247_PMRS_V1/plumber.webp')] z-[-1] mx-auto top-0 md:rounded-lg md:inset-x-[2.5rem] md:w-auto;
      }

      .check-list {
        counter-increment: css-counter 0;
      }      

      .checkbox {
        @apply  mb-[1rem] flex items-start;
        counter-increment: css-counter 1;
      }

      .checkbox-img{
        @apply mr-[1rem];
      }

      .process::before {
        content: counter(css-counter);
        font-style: normal;
        font-size: 1rem;
        font-weight: 600;
        background-color: #d01b34;
        color: #fff;
        display: block;
        text-align: center;
        border-radius: 100%;
        min-width: 1.7rem;
        min-height: 1.7rem;
      }

      @media (min-width: 1024px) {
        .sm\\:grid-cols-2 {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }

      .mobile-cta.mobile-cta-open{
        @apply opacity-100 visible translate-y-0 bottom-0;
      }


 }